import { useState } from 'react';
import { fetchDataFromApi } from './reusable_scripts/fetch_data';
import { DynamicTable } from './page_components/DynamicTable';
import { Box, Card, Button, Select, FormControl, InputLabel, MenuItem } from '@mui/material';

export function ActivityLog() {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [activityLog, setActivityLog] = useState([]);
  const [product, setProduct] = useState('');
  const [tableColumns, setTableColumns] = useState([]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    fetchDataFromApi('/activity-log?product=' + product)
      .then(data => {
        setActivityLog(data.Items);
        // Update columns based on the selected product
        const updatedColumns = [
          { field: 'product.S', headerName: 'Product' },
          { field: 'action.S', headerName: 'Action' },
          { field: 'requestTime.S', headerName: 'Request Time' },
          { field: 'email.S', headerName: 'Email' },
          product === 'Custom-Page-Studio' 
            ? { field: 'pageId.S', headerName: 'Page ID' } 
            : { field: 'blockId.S', headerName: 'Block ID' },
          { field: 'body.S', headerName: 'Values' }
        ];
        setTableColumns(updatedColumns);
        setDataLoaded(true);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  return (
    <Box sx={{ maxWidth: '80%', mx: 'auto', p: 3 }}>
      <Card sx={{ padding: '2%', marginBottom: '4%', borderRadius: '16px' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginBottom: 2 }}>
        <FormControl required sx={{ flexGrow: 1 }}>
          <InputLabel>Product</InputLabel>
          <Select
            label='Product'
            value= {product || ''}
            onChange={(e) => setProduct(e.target.value)}
          >
              <MenuItem value='Interface-Designer'>Interface Designer</MenuItem>
              <MenuItem value='Custom-Page-Studio'>Custom Page Studio</MenuItem>
          </Select>
        </FormControl>
        <Button onClick={handleFormSubmit} variant="contained">Get Records</Button>
      </Box>
      {!dataLoaded ? (
        <Box />
      ) : (
      <Box>
        <DynamicTable data={activityLog} columns={tableColumns} />
      </Box>
    )}
    </Card>
    </Box>
  );
}