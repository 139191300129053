import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

export function BlankPage() {
    const navigate = useNavigate();
    
    useEffect(() => {
        navigate('/'); // Redirect to home if access is denied
    }, []);

  return (
    <Box />
  );
}
