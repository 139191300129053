import { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Card, Box, Tab, Tabs, Typography, TextField, Button, IconButton, Avatar } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import logo from '../../logo.png';

export function AuthTabs({ initialAuthState, hideSignUp }) {
  const { signIn, signUp, confirmSignUp, forgotPassword, confirmForgotPassword, respondToNewPasswordRequiredChallenge } = useAuth();
  const [session, setSession] = useState(null);
  //const [tabIndex, setTabIndex] = useState(0);
  const initialTabIndex = initialAuthState === 'signUp' ? 1 : 0;
  const [tabIndex, setTabIndex] = useState(initialTabIndex);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');

  // Determine the welcome text based on the current tabIndex
  const getWelcomeText = () => {
    switch (tabIndex) {
      case 0:
        return "Ready to roll up your sleeves again?"; // For Sign In
      case 1:
        return "No credit card or coding skills required."; // For Sign Up
      default:
        return ""; // For other tabs or conditions
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
    setEmail('');
    setPassword('');
    setConfirmPassword('');
    setError('');
    setVerificationCode('');
  };

const handleSignIn = async (event) => {
  event.preventDefault();
  try {
    const response = await signIn(email, password);
    if (response.error) {
      setError("Incorrect username or password. Please try again.");
      return;
    }
    if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
      setTabIndex(5);
      setSession(response.session);
    }
  } catch (error) {
    setError(error.message);
  }
};

  // Helper function to validate password
  const isValidPassword = (password) => {
    const minLength = 8;
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
  
    return (
      password.length >= minLength &&
      hasNumber &&
      hasSpecialChar &&
      hasUppercase &&
      hasLowercase
    );
  };

const handleSignUp = async (event) => {
  event.preventDefault();
  if (password !== confirmPassword) {
    setError("Passwords do not match");
    return;
  }
  if (!isValidPassword(password)) {
    setError("Password must be at least 8 characters long, include a number, a special character, an uppercase letter, and a lowercase letter");
    return;
  }
  try {
    await signUp(email, password);
    setTabIndex(2); // Shift to verify email tab
  } catch (error) {
    setError(error.message);
  }
};
  
const handleVerifyCode = async (event) => {
  event.preventDefault();
  try {
    await confirmSignUp(email, verificationCode);
    await signIn(email, password);
  } catch (error) {
    setError(error.message);
  }
};

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

const handleForgotPasswordRequest = async (event) => {
  event.preventDefault();
  try {
    await forgotPassword(email);
    setTabIndex(4);
  } catch (error) {
    setError(error.message);
  }
};

const handleResetPassword = async (event) => {
  event.preventDefault();
  try {
    await confirmForgotPassword(email, verificationCode, newPassword);
    await signIn(email, newPassword); // Sign in the user automatically
  } catch (error) {
    setError(error.message);
  }
};

const handleSubmitNewPassword = async (event) => {
  event.preventDefault();
  try {
    const result = await respondToNewPasswordRequiredChallenge(email, session, newPassword);
    if (result.success) {
      await signIn(email, newPassword);
    } else {
      // Handle error, such as displaying a message to the user
      setError(result.message);
    }
  } catch (error) {
    setError(error.message);
  }
};



  return (
    <Card sx={{ p: 3, maxWidth: 450, mx: 'auto', mt: 4, borderRadius: '16px' }}>
    <Box textAlign="center" mb={2}>
        <Avatar src={logo} alt="Lernevate Logo" sx={{ mx: 'auto', width: 56, height: 56 }} variant="rounded" />
        {tabIndex <= 1 && (
          <Typography variant="h5" mt={2}>{getWelcomeText()}</Typography>
        )}
      </Box>
    <Tabs value={tabIndex} onChange={handleTabChange} centered>
      <Tab label="Sign In" sx={{ display: tabIndex === 2 ? 'none' : 'block' }} />
      {!hideSignUp && <Tab label="Sign Up" sx={{ display: tabIndex === 2 ? 'none' : 'block' }} />}
      <Tab label="Verify Email" sx={{ display: tabIndex === 2 ? 'block' : 'none' }} />
      <Tab label="Forgot Password" sx={{ display: tabIndex === 3 ? 'block' : 'none' }} />
      <Tab label="Reset Password" sx={{ display: tabIndex === 4 ? 'block' : 'none' }} />
      <Tab label="Reset Password" sx={{ display: tabIndex === 5 ? 'block' : 'none' }} />
    </Tabs>
      {tabIndex === 0 && (
        <Box component="form" textAlign="center" onSubmit={handleSignIn} sx={{ mt: 2 }}>
          {error && <Typography color="error">{error}</Typography>}
          <TextField
            margin="normal"
            required
            fullWidth
            label="Email"
            name="email"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={togglePasswordVisibility}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              ),
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          <Button
            onClick={() => setTabIndex(3)}
            sx={{ mt: 1 }}
            variant="text"
          >
            Forgot your password?
          </Button>
        </Box>
      )}
      {tabIndex === 1 && (
        <Box component="form" onSubmit={handleSignUp} sx={{ mt: 2 }}>
          {error && <Typography color="error">{error}</Typography>}
          <TextField
            margin="normal"
            required
            fullWidth
            label="Email"
            name="email"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={togglePasswordVisibility}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              ),
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="Confirm Password"
            type={showPassword ? "text" : "password"}
            autoComplete="current-password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <IconButton
                  aria-label="toggle confirm password visibility"
                  onClick={togglePasswordVisibility}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              ),
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign Up
          </Button>
        </Box>
      )}
      {tabIndex === 2 && (
        <Box component="form" onSubmit={handleVerifyCode} sx={{ mt: 2 }}>
          {error && <Typography color="error">{error}</Typography>}
          <TextField
            margin="normal"
            required
            fullWidth
            label="Verification Code"
            name="verificationCode"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            autoFocus
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Verify
          </Button>
        </Box>
      )}
      {tabIndex === 3 && (
        <Box component="form" onSubmit={handleForgotPasswordRequest} sx={{ mt: 2 }}>
          {error && <Typography color="error">{error}</Typography>}
          <TextField
            margin="normal"
            required
            fullWidth
            label="Email"
            name="email"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoFocus
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Request Reset
          </Button>
        </Box>
      )}
      {tabIndex === 4 && (
        <Box component="form" onSubmit={handleResetPassword} sx={{ mt: 2 }}>
          {error && <Typography color="error">{error}</Typography>}
          <TextField
            margin="normal"
            required
            fullWidth
            label="Verification Code"
            name="verificationCode"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="newPassword"
            label="New Password"
            type="password"
            autoComplete="current-password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Reset Password
          </Button>
        </Box>
      )}
      {tabIndex === 5 && (
        <Box component="form" onSubmit={handleSubmitNewPassword} sx={{ mt: 2 }}>
          {error && <Typography color="error">{error}</Typography>}
          <input type="hidden" name="email" value={email} />
          <TextField
            margin="normal"
            required
            fullWidth
            name="newPassword"
            label="New Password"
            type="password"
            autoComplete="current-password"
            value={newPassword}
            type={showPassword ? "text" : "password"}
            onChange={(e) => setNewPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={togglePasswordVisibility}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              ),
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Submit New Password
          </Button>
        </Box>
      )}

      <Typography variant="body2" textAlign="center" mt={3}>
        &copy; {new Date().getFullYear()} Lernevate. All rights reserved.
      </Typography>
    </Card>
  );
}