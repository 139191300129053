import { useState, useEffect, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Button, Card, CircularProgress } from '@mui/material';
import { CustomPageStudioDialog } from './page_components/CustomPageStudioDialog';
import { DynamicTable } from './page_components/DynamicTable';
import { fetchDataFromApi } from './reusable_scripts/fetch_data';
import AddIcon from '@mui/icons-material/Add';
import { AlertContext } from './page_components/AlertContext';
import { AlertSnackbar } from './page_components/AlertSnackbar';

export function CustomPageStudio({ colorMode }) {
  const [openDialog, setOpenDialog] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [pages, setPages] = useState([]);
  const { handleAddMessage } = useContext(AlertContext);
  let [searchParams, setSearchParams] = useSearchParams();
  const pageIdParam = searchParams.get('pageId'); // Get the pageId from URL
  
  const handleActionAlert = (actionType) => {
    if (actionType === 'page-created') {
        handleAddMessage('success', 'Page Successfully Created!');
        //fetchBlocks();
        setDataLoaded(false);
    } else if (actionType === 'page-updated') {
        handleAddMessage('success', 'Page Successfully Updated!');
        //fetchBlocks();
        setDataLoaded(false);
    }
  };

  const handleNewPageButton = (row) => {
    setOpenDialog(true);
    };
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSearchParams({});
  };

  useEffect(() => {
    // Fetch pages data
    fetchDataFromApi('/custom-page-studio/pages')
      .then(data => {
        setPages(data);
        setDataLoaded(true);
      })
      .catch(error => {
        console.error('Error pages:', error);
      });
    }, [dataLoaded]);
    
  useEffect(() => {
    if (pageIdParam) {
      setOpenDialog(true);
    }
  }, [pageIdParam]);
  
  const tableColumns = [
    { field: 'pageName', headerName: 'Name' },
    { field: 'pageDescription', headerName: 'Description' }
    // Add more columns as needed
  ];

  const handleRowClick = (row) => {
    const id = row.pageId; // Assuming each row has an 'id' property
    setOpenDialog(true);
    setSearchParams({ pageId: id });
  };

  return (
    <Box textAlign="center" sx={{ maxWidth: '80%', mx: 'auto', p: 3 }}>
      <AlertSnackbar />
      {!dataLoaded ? (
        <CircularProgress size={75} disableShrink />
      ) : (
        <Card sx={{ padding: '2%', borderRadius: '16px',}}>
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
            <Button variant="contained" onClick={handleNewPageButton} startIcon={<AddIcon />}>New Page</Button>
          </Box>
          <CustomPageStudioDialog
            openDialog={openDialog}
            handleCloseDialog={handleCloseDialog}
            colorMode={colorMode}
            onActionCompleted={handleActionAlert}
          />
          <DynamicTable data={pages} columns={tableColumns} onRowClick={handleRowClick}  />
        </Card>
      )}
    </Box>
  );
}
