import { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { useLocation } from 'react-router-dom';
import { Box, Link, Typography, IconButton, Button, List, Divider, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import logo from '../logo.png';
import BrushIcon from '@mui/icons-material/Brush';
import SettingsIcon from '@mui/icons-material/Settings';
import HistoryIcon from '@mui/icons-material/History';
//import EmailIcon from '@mui/icons-material/Email';
import PaletteIcon from '@mui/icons-material/Palette';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import CreateIcon from '@mui/icons-material/Create';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';

export function LeftNavBar({ toggleColorMode, currentMode, interfaceDesignerAccess, customPageStudioAccess, dashboardAccess }) {
  const location = useLocation();
  const { signOut } = useContext(AuthContext);
  
  const isCurrentPage = (href) => location.pathname === href;
  
  // List of page items
  const pages = [
    { text: 'Account Management', icon: <SettingsIcon />, href: '/account-management' },
    { text: 'Activity Log', icon: <HistoryIcon />, href: '/activity-log' },
    { text: 'Submit Issue/Request', icon: <CreateIcon />, href: '/issues-and-requests' },
  ];
  
  // List of page items
  let tools = [
    //{ text: 'Interface Designer', icon: <BrushIcon />, href: '/interface-designer' },
    //,
    //{ text: 'Email Management', icon: <EmailIcon />, href: '/email-management' },
    //{ text: 'Dashboards', icon: <QueryStatsIcon />, href: '/dashboards' },
  ];

  if (interfaceDesignerAccess) {
      tools.push({ text: 'Interface Designer', icon: <BrushIcon />, href: '/interface-designer' });
  }

  if (customPageStudioAccess) {
      tools.push({ text: 'Custom Page Studio', icon: <PaletteIcon />, href: '/custom-page-studio' });
  }

  if (dashboardAccess) {
      tools.push({ text: 'Dashboards', icon: <QueryStatsIcon />, href: '/dashboards' });
  }

return (
    <Box sx={{
      width: '100%', // Use 100% of the parent width
      maxWidth: '275px', // Set a maximum width of 275px
      minWidth: '10%', // Ensure it's at least 10% of the viewport width
      height: '100vh',
      boxSizing: 'border-box' // Include padding and border in the width calculation
      }}>
    <Box
      sx={{
        height: '100%',
        position: 'fixed',
        display: 'flex',
        flexDirection: 'column'
      }}
      role="presentation"
    >
    <Box sx={{ flexGrow: 1, marginLeft: '5px', overflow: 'auto' }}>
      <Typography variant="h4" component="div" sx={{ flexGrow: 1, marginTop: '10px', marginBottom: '40px', marginLeft: '5px' }}>
        <Link href="/" style={{ textDecoration: 'none', color: 'inherit' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
            <img src={logo} alt="logo" style={{ marginRight: '10px', height: '1em' }}/>
            LERNEVATE
          </Box>
        </Link>
      </Typography>
      <IconButton sx={{ ml: 1 }} onClick={toggleColorMode} color="inherit">
        {currentMode === 'dark' ? <LightModeIcon /> : <DarkModeIcon />}
      </IconButton>
      <Typography variant="h5" gutterBottom sx={{ margin: 2 }}>
        Tools
      </Typography>
      <List>
        {tools.map((item) => !isCurrentPage(item.href) && (
          <ListItem key={item.text} disablePadding>
            <Link href={item.href} color="inherit" style={{ textDecoration: 'none' }}>
              <ListItemButton sx={{ width: '100%' }}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
      </List>
      <Divider />
      <Typography variant="h5" gutterBottom sx={{ margin: 2 }}>
        Account
      </Typography>
      <List>
        {pages.map((item) => !isCurrentPage(item.href) && (
          <ListItem key={item.text} disablePadding>
            <Link href={item.href} color="inherit" style={{ textDecoration: 'none' }}>
              <ListItemButton sx={{ width: '100%' }}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
        <ListItem disablePadding>
            <Link href="https://lernevate.com/help-center/" target="_blank" color="inherit" style={{ textDecoration: 'none' }}>
              <ListItemButton>
                <ListItemIcon><MenuBookIcon /></ListItemIcon>
                <ListItemText primary="Resources" />
              </ListItemButton>
            </Link>
          </ListItem>
      </List>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center', // Center horizontally
        width: '100%', // Take the full width of the parent component
        marginTop: '20px' // Adjust the margin as needed
      }}>
        <Button variant="outlined" onClick={signOut}>Sign out</Button>
      </Box>
      </Box>
    </Box>
  </Box>
);
}
