import { useEffect, useState, useContext } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { AlertContext } from './AlertContext';

export function AlertSnackbar() {
  const { snackPack, setSnackPack } = useContext(AlertContext);
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState(undefined);

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  // This function will be used to add new messages to the snackPack
  //const handleAddMessage = (severity, message, title) => {
    //setSnackPack((prev) => [...prev, { message, severity, title, key: new Date().getTime() }]);
  //};

  return (
    <>
      {messageInfo && (
        <Snackbar
          key={messageInfo.key}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          TransitionProps={{ onExited: handleExited }}
        >
          <Alert
            onClose={handleClose}
            severity={messageInfo.severity}
            sx={{ width: '100%' }}
          >
            {messageInfo.title && <strong>{messageInfo.title}</strong>} {messageInfo.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}