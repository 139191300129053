import { useState, createContext } from 'react';

export const AlertContext = createContext();

export const AlertProvider = ({ children }) => {
  const [snackPack, setSnackPack] = useState([]);

  const handleAddMessage = (severity, message, title) => {
    setSnackPack((prev) => [...prev, { message, severity, title, key: new Date().getTime() }]);
  };

  return (
    <AlertContext.Provider value={{ snackPack, setSnackPack, handleAddMessage }}>
      {children}
    </AlertContext.Provider>
  );
};
