import { useState } from 'react';
import { fetchDataFromApi } from './reusable_scripts/fetch_data';
import { useAuth } from '../contexts/AuthContext';
import { Box, TextField, Button, FormControl, Card } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

export function IssuesAndRequests() {
    const { user } = useAuth();
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        
        try {
            const requestData = {
              submitter_email: user.email, // Assuming email is stored directly in the user object
              title: title,
              description: description
            };
            console.log('Request Data:', requestData); // Check if the request data looks correct
            const response = await fetchDataFromApi('/issues', 'POST', requestData);
            console.log('Response:', response);
            setTitle('');
            setDescription('');
        } catch (error) {
          console.error('Error submitting form:', error);
        }
    };

  return (
      <Box sx={{ maxWidth: '80%', mx: 'auto', p: 3 }}>
        <Card sx={{ padding: '2%', marginBottom: '4%', borderRadius: '16px' }}>
          <FormControl fullWidth>
            <TextField id="title" value={title} label="Title" variant="outlined" onChange={(e) => setTitle(e.target.value)} sx={{ mb: 2 }} />
            <TextField id="description" value={description} label="Description" variant="outlined" minRows={4} multiline onChange={(e) => setDescription(e.target.value)} sx={{ mb: 2 }} />
            <Button fullWidth variant="contained" startIcon={<SaveIcon />} onClick={handleFormSubmit}>Submit</Button>
          </FormControl>
        </Card>
      </Box>

  );
}