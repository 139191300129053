export async function fetchDataFromApi(path, method = 'GET', body = null) {
  try {
    const idToken = localStorage.getItem('idToken');
    const headers = {
      Authorization: `Bearer ${idToken}`,
    };

    let requestOptions = {
      method: method,
      headers: headers,
    };

    // For POST requests, add the body to the request options
    if (method === 'POST' && body) {
      requestOptions.body = JSON.stringify(body);
    }

    const apiUrl = 'https://ce3ff8yhzj.execute-api.us-east-1.amazonaws.com/dev';
    const response = await fetch(`${apiUrl}${path}`, requestOptions);
    
    if (!response.ok) {
      //throw new Error(`HTTP error! Status: ${response.status}`);
      return { error: true, message: 'An error occurred. Please try again later.' };
    }

    const data = await response.json();
    //console.log('data', data); only include for testing
    return data;

  } catch (error) {
    //console.error('Error:', error); only include for testing
    return { error: true, message: 'An error occurred. Please try again later.' };
  }
}
