import { useEffect, useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { fetchDataFromApi } from '../reusable_scripts/fetch_data';
import { Box, Grid, Button, TextField, CircularProgress, Alert, Typography, Select, MenuItem, DialogActions, FormControl, InputLabel, FormHelperText } from '@mui/material';

export function InterfaceDesignerDialog({ id, isNew, onClose, onActionCompleted }) {
  const pageLoad = useRef(true);
  
  const [searchParams] = useSearchParams();
  const blockIdParam = searchParams.get('blockId');
  const templateIdParam = searchParams.get('templateId');
  
  //const isEditMode = !isNew;
  const isEditMode = blockIdParam !== null && blockIdParam !== undefined;
  const [templateData, setTemplateData] = useState(null);
  const [formData, setFormData] = useState({});
  const [dataLoaded, setDataLoaded] = useState(false);
  const [maxBlocks, setMaxBlocks] = useState(0);
  
  const [productionBlockCount, setProductionBlockCount] = useState(0);
  const [stageBlockCount, setStageBlockCount] = useState(0);
  const [pilotBlockCount, setPilotBlockCount] = useState(0);

  // Compare the counts and the max value for conditional rendering and button disabling
  const shouldDisableButton =
    !isEditMode &&
    ((formData.environment === 'Production' && productionBlockCount >= maxBlocks) ||
      (formData.environment === 'Pilot' && pilotBlockCount >= maxBlocks) ||
      (formData.environment === 'Stage' && stageBlockCount >= maxBlocks));

  useEffect(() => {
    if(pageLoad.current){
      pageLoad.current = false;
      const fetchTemplateData = async () => {
        try {
          // Determine whether it's a block or template based on parameters
          const isBlock = blockIdParam !== null;
          const idToUse = isBlock ? blockIdParam : templateIdParam;
        
          const endpoint = isBlock 
            ? `/tag-manager/blocks/${idToUse}` 
            : `/tag-manager/templates/${idToUse}`;
    
          const data = await fetchDataFromApi(endpoint);
          setTemplateData(data);
          
          // If it's edit mode and you have templateData, set formData from templateData
          if (isEditMode && data) {
            const newFormData = {
              blockId: data.blockId  || '',
              environment: data.environment || '',
              isEnabled: data.isEnabled || '',
              templateId: data.templateId || '',
              description: data.description || '',
              variables: data.selections || [],
            };
  
            setFormData(newFormData);
            setDataLoaded(true);
          } else {
            const newFormData = {
              environment: '',
              templateId: id,
              isEnabled: 'True',
              description: '',
              variables: [],
            };
            
            setFormData(newFormData);
            setDataLoaded(true);
          }
        } catch (error) {
          // Handle error here
          console.error('Error fetching data:', error);
        }
      };
    
      fetchTemplateData();
      
      if (!isEditMode) {
        // Fetch max blocks
        fetchDataFromApi('/client-limits?type=blocks&details=true')
          .then((data) => {
            setMaxBlocks(data.max);
            setProductionBlockCount(data.productionCount);
            setStageBlockCount(data.stageCount);
            setPilotBlockCount(data.pilotCount);
            
          })
          .catch((error) => {
            console.error('Error:', error);
          });
      }
      
    }
  }, [id, isEditMode]);

 // Use a separate useEffect to handle default values for variables
  useEffect(() => {
    if (!isEditMode && templateData && templateData.variables) {
      const newFormData = { ...formData };

      templateData.variables.forEach((variable) => {
        if (variable.variableType === 'dropdown' && variable.options.length > 0) {
          // Check if the variableKey already exists in newFormData
          const variableKeyExists = newFormData.variables.some(item => item.variableKey === variable.variableKey);
  
          // Only push if the variableKey doesn't exist in newFormData
          if (!variableKeyExists) {
            newFormData.variables.push({ 'variableKey': variable.variableKey, 'optionValue': variable.options[0].optionValue });
          }
        }
      });

      setFormData(newFormData);
    }
  }, [isEditMode, templateData]);

const handleVariableChange = (variableKey, optionValue) => {
  setFormData(prevData => {
    // Find the index of the variable in the array
    const index = prevData.variables.findIndex(item => item.variableKey === variableKey);

    let updatedVariables;
    if (index >= 0) {
      // Variable already exists, update its value
      updatedVariables = prevData.variables.map((item, i) =>
        i === index ? { ...item, optionValue: optionValue } : item
      );
    } else {
      // Variable doesn't exist, add it to the array
      updatedVariables = [
        ...prevData.variables,
        { variableKey: variableKey, optionValue: optionValue }
      ];
    }

    return {
      ...prevData,
      variables: updatedVariables
    };
  });
};

  // Render variable components based on templateData
  const renderVariableComponents = () => {
    if (!templateData || !templateData.variables) {
      return null;
    }
    
  return templateData.variables.map((variable) => {
    // Find the corresponding entry in formData.variables
    const formDataEntry = formData.variables.find(v => v.variableKey === variable.variableKey);

    if (variable.variableType === 'dropdown') {
      return (
        <FormControl key={variable.variableKey} required fullWidth>
          <InputLabel>{variable.variableName}</InputLabel>
          <Select
            label={variable.variableName}
            value={formDataEntry ? formDataEntry.optionValue : ''}
            onChange={(e) => handleVariableChange(variable.variableKey, e.target.value)}
          >
            {variable.options.map((option) => (
              <MenuItem key={option.optionValue} value={option.optionValue}>
                {option.optionName}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{variable.variableDescription}</FormHelperText>
        </FormControl>
      );
      } else if (variable.variableType === 'input') {
        return (
          <FormControl key={variable.variableKey} fullWidth>
            <TextField
              label={variable.variableName}
              required
              value={formDataEntry ? formDataEntry.optionValue : ''}
              onChange={(e) => handleVariableChange(variable.variableKey, e.target.value)}
            />
            <FormHelperText>{variable.variableDescription}</FormHelperText>
          </FormControl>
        );
      } else {
        // Handle other variable types here
        return null;
      }
    });
  };

const handleFormSubmit = async (e) => {
  e.preventDefault();

  let requestData = {};

  if (isEditMode) {
    // In edit mode, include only description and variables
    requestData = {
      description: formData.description,
      variables: formData.variables,
    };
  } else {
    // In create mode, include all form data
    requestData = { ...formData };
  }

  const request_body = JSON.stringify(requestData); // Stringify the requestData
  console.log('Request Data:', request_body); // Check if the request data looks correct

  try {
    if (isEditMode) {
      requestData = {
        description: formData.description,
        variables: formData.variables,
      };
      const response = await fetchDataFromApi('/tag-manager/blocks/' + id, 'POST', request_body);
      console.log('Edit Mode Response:', response); // Check the response in edit mode
      //navigate('/interface-designer?action=update-block');
      onActionCompleted('block-updated');
    } else {
      const response = await fetchDataFromApi('/tag-manager/blocks', 'POST', request_body);
      console.log('Create Mode Response:', response); // Check the response in create mode
      //navigate('/interface-designer?action=create-block');
      onActionCompleted('block-created');
    }
    
    onClose(); // Close the dialog after successful submission
    
  } catch (error) {
    console.error('Error submitting form:', error);
  }
};

  return (
    <Box textAlign="center" sx={{ padding: '2%', width: 'auto' }}>
      {shouldDisableButton && (
        <Alert severity="warning">
          You have met the Block limit based on your subscription plan. You can either disable a Block or upgrade your plan to access additional Blocks.
        </Alert>
      )}
      <Typography variant="h4" gutterBottom align="center">
        {isEditMode ? 'Edit Block' : 'Create New Block'}
      </Typography>
      {!dataLoaded ? (
        <CircularProgress />
      ) : (
        <form>
          {templateData && templateData.templateName && (
            <Typography variant="h6" gutterBottom>
              Template: {templateData.templateName}
            </Typography>
          )}
          <Grid container direction="column" gap={2}>
            <FormControl required disabled={!!templateData?.environment} fullWidth>
              <InputLabel>Environment</InputLabel>
              <Select
                label="Environment"
                value={formData.environment || ''}
                onChange={(e) => setFormData((prevData) => ({ ...prevData, environment: e.target.value }))}
              >
                <MenuItem value="">Select Environment</MenuItem>
                <MenuItem value="Production">Production</MenuItem>
                <MenuItem value="Pilot">Pilot</MenuItem>
                <MenuItem value="Stage">Stage</MenuItem>
              </Select>
            </FormControl>
            {renderVariableComponents()}
            <TextField
              label="Description"
              helperText="Add any relevant information about this block you would like."
              value={formData.description || ''}
              onChange={(e) => setFormData((prevData) => ({ ...prevData, description: e.target.value }))}
            />
            <DialogActions>
              <Button onClick={onClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleFormSubmit} variant="contained" disabled={shouldDisableButton}>
                {isEditMode ? 'Update' : 'Create'}
              </Button>
            </DialogActions>
          </Grid>
        </form>
      )}
    </Box>
  );
}