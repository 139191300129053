import { useState, useEffect, useRef, useContext, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { fetchDataFromApi } from './reusable_scripts/fetch_data';
import { DynamicTable } from './page_components/DynamicTable';
import { TemplateCollection } from './page_components/TemplateCollection';
import { Box, Card, Typography, Alert, AlertTitle, CircularProgress, Dialog, Tabs, Tab, Button } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { InterfaceDesignerDialog } from './page_components/InterfaceDesignerDialog';
import { AlertContext } from './page_components/AlertContext';
import { AlertSnackbar } from './page_components/AlertSnackbar';

export function InterfaceDesigner({ userRole }) {
  const pageLoad = useRef(true);
  let [searchParams, setSearchParams] = useSearchParams();
  const blockIdParam = searchParams.get('blockId'); // Get the blockId from URL
  const templateIdParam = searchParams.get('templateId'); // Get the blockId from URL
  
  const { handleAddMessage } = useContext(AlertContext);
  
  //need to add this message:  message="Note that you only need to add this tag to Google Tag Manager once per environment."
  
  const handleActionAlert = (actionType) => {
    if (actionType === 'block-created') {
        handleAddMessage('success', 'Block Successfully Created!');
        fetchBlocks();
    } else if (actionType === 'block-updated') {
        handleAddMessage('success', 'Block Successfully Updated!');
        fetchBlocks();
    } else if (actionType === 'tag-copied') {
        handleAddMessage('success', 'Tag Successfully Copied!');
    }
  };

  const [activeTab, setActiveTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  
  const [templates, setTemplates] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [files, setFiles] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [maxBlocks, setMaxBlocks] = useState(0);
  
  const [productionData, setProductionData] = useState([]);
  const [stageData, setStageData] = useState([]);
  const [pilotData, setPilotData] = useState([]);
  
  const [productionBlockCount, setProductionBlockCount] = useState(0);
  const [stageBlockCount, setStageBlockCount] = useState(0);
  const [pilotBlockCount, setPilotBlockCount] = useState(0);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentId, setCurrentId] = useState(null);

  const handleOpenDialog = useCallback((id, isTemplate = false) => {
    setCurrentId(id);
    setIsDialogOpen(true);
    setSearchParams(isTemplate ? { templateId: id } : { blockId: id });
  }, [setSearchParams]);
  
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setCurrentId(null);
    setSearchParams({}); // Clear the search parameters
  };

  useEffect(() => {
    if (blockIdParam) {
      handleOpenDialog(blockIdParam);
    } else if (templateIdParam) {
      handleOpenDialog(templateIdParam, true); // Assuming the second argument indicates a template
    }
    // Additional data fetching and logic here if needed
  }, [blockIdParam, templateIdParam, handleOpenDialog]); // Add templateIdParam as a dependency

  const fetchBlocks = () => {
    // Fetch blocks data
    fetchDataFromApi('/tag-manager/blocks')
      .then(data => {
        const blocksData = data.blocks;
        setBlocks(blocksData);
        
        // Update the initial count of enabled blocks for each environment
        const productionBlocks = blocksData.filter(item => item.environment === 'Production');
        const stageBlocks = blocksData.filter(item => item.environment === 'Stage');
        const pilotBlocks = blocksData.filter(item => item.environment === 'Pilot');
  
        setProductionData(productionBlocks);
        setStageData(stageBlocks);
        setPilotData(pilotBlocks);
  
        setProductionBlockCount(productionBlocks.filter(item => item.isEnabled === 'True').length);
        setStageBlockCount(stageBlocks.filter(item => item.isEnabled === 'True').length);
        setPilotBlockCount(pilotBlocks.filter(item => item.isEnabled === 'True').length);
        
        setFiles(data.files);
        setDataLoaded(true);
        console.log('data', data);
      })
      .catch(error => {
        console.error('Error blocks:', error);
      });
  };

  useEffect(() => {
    if(pageLoad.current){
      pageLoad.current = false;
      
      fetchDataFromApi('/client-limits?type=blocks')
        .then(data => {
          setMaxBlocks(data.max);
        })
        .catch(error => {
          console.error('Error:', error);
        });
      
      // Fetch templates data
      fetchDataFromApi('/tag-manager/templates')
        .then(data => {
          setTemplates(data);
        })
        .catch(error => {
          console.error('Error templates:', error);
        });
        
      fetchBlocks();
    }
  }, [userRole]);

  const columnsToIncludeForTables = [
    { field: 'templateName', headerName: 'Template Name' },
    { field: 'description', headerName: 'Description' },
    { field: 'isEnabled', headerName: 'Enabled' }
  ];
  
  const CopyToClipboardComponent = ({ fileUrl, onTagCopied }) => {
      const copyToClipboard = (textToCopy) => {
          const textarea = document.createElement('textarea');
          textarea.value = textToCopy;
          document.body.appendChild(textarea);
          textarea.select();
          document.execCommand('copy');
          document.body.removeChild(textarea);
        
          // Notify that the tag has been copied
          if (onTagCopied) {
              onTagCopied('tag-copied');
          }
      };
    
      return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button 
                variant="outlined" 
                onClick={() => copyToClipboard(`<script src="${fileUrl}"></script>`)}
                sx={{ display: 'flex', alignItems: 'center' }}
                startIcon={<ContentCopyIcon />}
            >
                Copy Google Tag Manager tag
            </Button>
        </Box>
      );
  };
  
  // Function to get URL for a given environment
  const getFileUrlForEnvironment = (environment) => {
    const file = files.find(f => f.environment === environment);
    return file ? file.url : '';
  };

  // Merged function for handling switch change
  const handleSwitchChange = async (newData, rowIndex, columnField, checked) => {
    if (columnField === 'isEnabled') {
      const block = newData[rowIndex];
      const requestData = {
        'isEnabled': checked ? 'True' : 'False'
      };

      const request_body = JSON.stringify(requestData);
      try {
        await fetchDataFromApi('/tag-manager/blocks/' + block.blockId, 'POST', request_body);

        handleActionAlert('block-updated');
      } catch (error) {
        console.error('Error submitting form:', error);
      }
    }
  };

  const handleRowClick = (row) => {
    handleOpenDialog(row.blockId);
  };

  return (
<Box textAlign="center" sx={{ maxWidth: '80%', mx: 'auto', p: 3 }}>
  <AlertSnackbar />
  {!dataLoaded ? (
    <CircularProgress size={75} disableShrink />
  ) : (
      <Box>
      {['super_admin', 'admin'].includes(userRole) && (
        <Card sx={{ padding: '2%', marginBottom: '4%', borderRadius: '16px' }}>
          <Typography variant="h5" sx={{ paddingBottom: '2%' }} gutterBottom>Templates</Typography>
          <TemplateCollection templates={templates} onTemplateClick={(id) => handleOpenDialog(id, true)} />
        </Card>
      )}
      <Card sx={{ padding: '2%', borderRadius: '16px',}}>
        <Typography variant="h5"  sx={{ paddingBottom: '2%' }} gutterBottom>Your Blocks</Typography>
        
        <Tabs value={activeTab} onChange={handleTabChange} aria-label="block tabs">
          <Tab label="Production" />
          <Tab label="Stage" />
          <Tab label="Pilot" />
        </Tabs>
        {activeTab === 0 && (
          <Box>
            {userRole === "super_admin" && (
              <CopyToClipboardComponent fileUrl={getFileUrlForEnvironment('Production')} onTagCopied={handleActionAlert}/>
            )}
            {
              ['super_admin'].includes(userRole)  && dataLoaded && (
                productionBlockCount >= maxBlocks && (
                  <Alert severity="warning">
                    <AlertTitle>Block Limit Reached</AlertTitle>
                    You have met the Block limit based on your subscription plan. You can either disable a Block or upgrade your plan to access additional Blocks.
                  </Alert>
                )
              )
            }
            <DynamicTable data={productionData} columns={columnsToIncludeForTables} onSwitchChange={handleSwitchChange} onRowClick={handleRowClick} isSwitchDisabled={productionBlockCount >= maxBlocks} />
          </Box>
        )}
        {activeTab === 1 && (
          <Box>
            {userRole === "super_admin" && (
              <CopyToClipboardComponent fileUrl={getFileUrlForEnvironment('Stage')} onTagCopied={handleActionAlert} />
            )}
            {
              ['super_admin'].includes(userRole)  && dataLoaded && (
               stageBlockCount >= maxBlocks && (
                  <Alert severity="warning">
                    <AlertTitle>Block Limit Reached</AlertTitle>
                    You have met the Block limit based on your subscription plan. You can either disable a Block or upgrade your plan to access additional Blocks.
                  </Alert>
                )
              )
            }
            <DynamicTable data={stageData} columns={columnsToIncludeForTables} onSwitchChange={handleSwitchChange} onRowClick={handleRowClick} isSwitchDisabled={stageBlockCount >= maxBlocks} />
          </Box>
        )}
        {activeTab === 2 && (
          <Box>
            {userRole === "super_admin" && (
              <CopyToClipboardComponent fileUrl={getFileUrlForEnvironment('Pilot')} onTagCopied={handleActionAlert} />
            )}
            {
              ['super_admin'].includes(userRole)  && dataLoaded && (
                pilotBlockCount >= maxBlocks && (
                  <Alert severity="warning">
                    <AlertTitle>Block Limit Reached</AlertTitle>
                    You have met the Block limit based on your subscription plan. You can either disable a Block or upgrade your plan to access additional Blocks.
                  </Alert>
                )
              )
            }
            <DynamicTable data={pilotData} columns={columnsToIncludeForTables} onSwitchChange={handleSwitchChange} onRowClick={handleRowClick} isSwitchDisabled={pilotBlockCount >= maxBlocks} />
          </Box>
        )}
      </Card>
      <Dialog open={isDialogOpen} onClose={handleCloseDialog} maxWidth="md" fullWidth>
      <InterfaceDesignerDialog id={currentId} isNew={!currentId} onClose={handleCloseDialog} onActionCompleted={handleActionAlert} />
    </Dialog>
    </Box>
  )}
</Box>
  );
}