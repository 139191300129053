import { Button, Box, Typography } from '@mui/material';

export function CustomPageStudioCodeButtons({ onInsert }) {
  const insertCode = (codeString) => {
    if (onInsert) {
      onInsert(codeString);
    }
  };

const listCode = `
  <ul id="myList"></ul>
  <script>
    const items = ['Item 1', 'Item 2', 'Item 3'];
    const ul = document.getElementById('myList');
    items.forEach(item => {
        const li = document.createElement('li');
        li.textContent = item;
        ul.appendChild(li);
    });
  </script>
`;

const navBarCode = `
<style>
    /* Add some basic styling for the navigation bar */
    ul.navbar {
        list-style-type: none;
        margin: 0;
        padding: 0;
        background-color: #333;
        overflow: hidden;
    }

    li.nav-item {
        float: left;
    }

    li.nav-item a {
        display: block;
        color: white;
        text-align: center;
        padding: 14px 16px;
        text-decoration: none;
    }

    li.nav-item a:hover {
        background-color: #ddd;
        color: black;
    }
</style>
<ul class="navbar">
    <!-- Tabs will be dynamically added here -->
</ul>

<div class="tab-content">
    <!-- Tab content will be dynamically displayed here -->
</div>

<script>
    // Array of tabs with names, IDs, and content
    const tabs = [
        {
            name: "Tab 1",
            id: "tab1",
            content: "Content for Tab 1 goes here."
        },
        {
            name: "Tab 2",
            id: "tab2",
            content: "Content for Tab 2 goes here."
        },
        {
            name: "Tab 3",
            id: "tab3",
            content: "Content for Tab 3 goes here."
        },
    ];

    // Create the navigation bar and tabs based on the array
    const navbar = document.querySelector(".navbar");
    const tabContent = document.querySelector(".tab-content");

    tabs.forEach(tab => {
        const listItem = document.createElement("li");
        listItem.classList.add("nav-item");
        const link = document.createElement("a");
        link.href = "#"; // You can set the actual URLs here if needed
        link.id = tab.id;
        link.textContent = tab.name;
        listItem.appendChild(link);
        navbar.appendChild(listItem);

        // Add event listener to display tab content when clicked
        link.addEventListener("click", () => {
            tabContent.textContent = tab.content;
        });
    });
</script>
`;

  return (
    <Box textAlign="center" sx={{ display: 'flex', flexDirection: 'column', mr: 2, gap: 1 }}>
        <Typography variant="h5" gutterBottom>Insert</Typography>
        <Button variant='outlined' onClick={() => insertCode('<div></div>')}>Div</Button>
        <Button variant='outlined' onClick={() => insertCode('<p></p>')}>Paragraph</Button>
        <Button variant='outlined' onClick={() => insertCode('<img src="" alt="" />')}>Image</Button>
        <Button variant='outlined' onClick={() => insertCode(listCode)}>List</Button>
        <Button variant='outlined' onClick={() => insertCode('<button id="myButton">Click Me!</button>')}>Button</Button>
        <Button variant='outlined' onClick={() => insertCode(navBarCode)}>Nav Bar</Button>
    </Box>
  );
}
