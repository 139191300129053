import { AuthProvider } from './contexts/AuthContext';
import { MainContent } from './MainContent';
import { createTheme, ThemeProvider, CssBaseline, useMediaQuery } from '@mui/material';
import { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { AlertProvider } from './pages/page_components/AlertContext';

export default function App() {
  const location = useLocation();

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [mode, setMode] = useState(() => localStorage.getItem('themeMode') || (prefersDarkMode ? 'dark' : 'light'));
  
  useEffect(() => {
    localStorage.setItem('themeMode', mode);
  }, [mode]);
  /*
  useEffect(() => {
    console.log('hostname', window.location.hostname);
  }, []);
  */
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: mode,
        },
      }),
    [mode],
  );

  const toggleColorMode = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  const getInitialState = () => {
    const path = location.pathname;
    if (path === '/sign-up') {
      return 'signUp';
    }
    return 'signIn';
  };

  const [initialState, setInitialState] = useState(getInitialState());

  useEffect(() => {
    setInitialState(getInitialState());
  }, [location]);

  const [hideSignUp, setHideSignUp] = useState(false);
  
  useEffect(() => {
    setHideSignUp(location.pathname === '/hide-sign-up');
  }, [location]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <AlertProvider>
          <MainContent toggleColorMode={toggleColorMode} currentMode={mode} initialAuthState={initialState} hideSignUp={hideSignUp} />
        </AlertProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}