import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';
import { Home } from './pages/Home';
import { InterfaceDesigner } from './pages/InterfaceDesigner';
import { CustomPageStudio } from './pages/CustomPageStudio';
import { Dashboard } from './pages/Dashboard';
import { AccountManagement } from './pages/AccountManagement';
import { IssuesAndRequests } from './pages/IssuesAndRequests';
import { ActivityLog } from './pages/ActivityLog';
import { LeftNavBar } from './pages/LeftNavBar';
import { BlankPage } from './pages/BlankPage';
import { AuthTabs } from './pages/page_components/AuthTabs';
import { Box, Typography } from '@mui/material';
import { fetchDataFromApi } from './pages/reusable_scripts/fetch_data';

import HomeIcon from '@mui/icons-material/Home';
import BrushIcon from '@mui/icons-material/Brush';
import SettingsIcon from '@mui/icons-material/Settings';
import HistoryIcon from '@mui/icons-material/History';
import PaletteIcon from '@mui/icons-material/Palette';
import CreateIcon from '@mui/icons-material/Create';
import QueryStatsIcon from '@mui/icons-material/QueryStats';

const routeHeaders = {
    '/account-management': { icon: <SettingsIcon />, title: 'Account Management' },
    '/activity-log': { icon: <HistoryIcon />, title: 'Activity Log' },
    '/issues-and-requests': { icon: <CreateIcon />, title: 'Submit Issue/Request' },
    '/interface-designer': { icon: <BrushIcon />, title: 'Interface Designer' },
    '/custom-page-studio': { icon: <PaletteIcon />, title: 'Custom Page Studio' },
    '/dashboards': { icon: <QueryStatsIcon />, title: 'Dashboards' },
    '/': { icon: <HomeIcon />, title: 'Home' }
};

    //'/email-management': { icon: <EmailIcon />, title: 'Email Management' },
    //'/dashboards': { icon: <QueryStatsIcon />, title: 'Dashboards' },

export function MainContent({ toggleColorMode, currentMode, initialAuthState, hideSignUp }) {
  const { user } = useAuth();
  const { pathname } = useLocation();

  let userPermissions = user && user['custom:permissions'] ? user['custom:permissions'] : 'default_permissions';
  const [interfaceDesignerAccess, setInterfaceDesignerAccess] = useState(true);
  const [customPageStudioAccess, setCustomPageStudioAccess] = useState(true);
  const [dashboardAccess, setDashboardAccess] = useState(true);

useEffect(() => {
  if (user) {
    fetchDataFromApi('/client-limits?type=blocks')
      .then(data => {
        // Check if data.max is defined and equal to anything other than 0
        if (data && Number(data.max) !== 0) {
          setInterfaceDesignerAccess(true);
        } else {
          setInterfaceDesignerAccess(false);
        }
      })
      .catch(error => {
        console.error('Error:', error);
        setInterfaceDesignerAccess(false);
      });
    fetchDataFromApi('/client-limits?type=custom-page-studio-access')
      .then(data => {
        // Check if data.max is defined and equals 1
        if (data && Number(data.max) === 1) {
          setCustomPageStudioAccess(true);
        } else {
          setCustomPageStudioAccess(false);
        }
      })
      .catch(error => {
        console.error('Error:', error);
        setCustomPageStudioAccess(false);
      });
    fetchDataFromApi('/client-limits?type=dashboard-access')
      .then(data => {
        // Check if data.max is defined and equals 1
        if (data && Number(data.max) === 1) {
          setDashboardAccess(true);
        } else {
          setDashboardAccess(false);
        }
      })
      .catch(error => {
        console.error('Error:', error);
        setDashboardAccess(false);
      });
  }
}, [user]);

    // Function to match pathname with routeHeaders
    const getRouteHeader = (path) => {
        for (let key in routeHeaders) {
            if (path.startsWith(key)) {
                return routeHeaders[key];
            }
        }
        return {};
    };

    const { icon, title } = getRouteHeader(pathname);

    if (!user) {
        // User is not authenticated, show the AuthTabs component
        return <AuthTabs initialAuthState={initialAuthState} hideSignUp={hideSignUp} />;
    }



    //<Route path="/interface-designer/new/:id" element={['super_admin', 'admin'].includes(user.attributes['custom:permissions']) ? <InterfaceDesignerForm isNew={true} userRole={user.attributes['custom:permissions']} /> : <Home />} />
    //<Route path="/interface-designer/edit/:id" element={<InterfaceDesignerForm isNew={false} userRole={user.attributes['custom:permissions']} />} />
    //<Route path="/email_management" element={<EmailCampaignList />} />
    //<Route path="/email_management/new" element={<EmailForm isNew={true} />} />
    //<Route path="/email_management/edit/:id" element={<EmailForm isNew={false} />} />

    // User is authenticated, show the main content of your application
    return (
        <Box sx={{ display: 'flex', width: '100vw', minHeight: '100vh' }}>
            <LeftNavBar toggleColorMode={toggleColorMode} currentMode={currentMode} interfaceDesignerAccess={interfaceDesignerAccess} customPageStudioAccess={customPageStudioAccess} dashboardAccess={dashboardAccess} />
            <Box sx={{ flexGrow: 1, overflow: 'auto', width: `calc(100vw - 250px)` }}>
                <Box sx={{ 
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  p: 2,
                }}>
                  {icon && React.isValidElement(icon) && React.cloneElement(icon, { fontSize: 'large' })}
                  <Typography variant="h4" sx={{ ml: 1 }}>
                    {title}
                  </Typography>
                </Box>
                    <Box 
                      sx={{ 
                        mx: 'auto', 
                        p: 3, 
                        maxWidth: 'calc(100vw - 250px)',
                        bgcolor: currentMode === 'light' ? 'grey.A100' : 'inherit', // 'inherit' or any other default color
                        height: '100%'       // Take up 100% of the container height
                      }}
                    >
                    <Routes>
                        <Route
                            key="home"
                            path="/"
                            element={<Home />}
                        />
                        <Route
                            path="/interface-designer"
                            element={interfaceDesignerAccess
                              ? <InterfaceDesigner userRole={userPermissions} />
                              : <BlankPage />}
                        />
                        <Route
                            path="/custom-page-studio"
                            element={customPageStudioAccess
                              ? <CustomPageStudio colorMode={currentMode} />
                              : <BlankPage />}
                        />
                        <Route
                            path="/dashboards"
                            element={dashboardAccess
                              ? <Dashboard />
                              : <BlankPage />}
                        />
                        <Route
                          path="/account-management"
                          element={userPermissions === 'super_admin'
                            ? <AccountManagement />
                            : <BlankPage />}
                        />
                        <Route
                            path="/issues-and-requests"
                            element={<IssuesAndRequests />}
                        />
                        <Route
                          path="/activity-log"
                          element={['super_admin', 'admin'].includes(userPermissions)
                            ? <ActivityLog />
                            : <BlankPage />}
                        />
                     </Routes>
                </Box>
            </Box>
        </Box>
    );
}