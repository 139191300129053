import { useRef, useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { fetchDataFromApi } from './reusable_scripts/fetch_data';

export function Dashboard() {
  
  const [isLoading, setIsLoading] = useState(true); // State to track loading status
  const [embedUrl, setEmbedUrl] = useState(null); // Store the URL in state
  const iframeRef = useRef(null);

  const handleGenerateURL = async () => {
    fetchDataFromApi('/quicksight-embed-url')
        .then(data => {
      const url = data.url;

      if (url) {
        setEmbedUrl(url);
        setIsLoading(false);
      } else {
        // Handle the case where the URL is not in the expected format or missing
        console.error('URL not found in response');
        setIsLoading(false);
      }
    })
    .catch(error => {
      console.error('Error fetching embed URL:', error);
      setIsLoading(false);
    });
  };
  
  useEffect(() => {
    handleGenerateURL();
  }, []);

  useEffect(() => {
    if (embedUrl && iframeRef.current) {
      iframeRef.current.src = embedUrl;
    }
  }, [embedUrl]);
  
  return (
    <Box textAlign="center" sx={{ height: '100%', width: '100%' }}>
        {isLoading ? (
          <CircularProgress size={75} />
        ) : (
          <iframe
            ref={iframeRef}
            title="Embedded QuickSight Dashboard"
            style={{ width: '90%', height: '90%', border: 'none' }}
          />
        )}
    </Box>
  );
}