import { Box, Typography } from '@mui/material';

export function Home() {

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center', // Center vertically
        alignItems: 'center',     // Center horizontally
        height: '70vh',          // Take full viewport height
        textAlign: 'center'
      }}
    >
      <Typography variant="h3" gutterBottom>Welcome to the Lernevate tool ecosystem!</Typography>
      <Typography variant="h5">Please use the sidebar to navigate.</Typography>
    </Box>
  );
}
