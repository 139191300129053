import { useState } from 'react';
import { Grid, Button, TextField, Box, Pagination } from '@mui/material';

export function TemplateCollection({ templates, onTemplateClick }) {
  const [searchKeyword, setSearchKeyword] = useState('');
  const [page, setPage] = useState(1);
  const itemsPerPage = 9;

  const filteredTemplates = templates.filter((template) =>
    template.templateName.toLowerCase().includes(searchKeyword.toLowerCase())
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Calculates the current items to display based on pagination
  const paginatedTemplates = filteredTemplates.slice((page - 1) * itemsPerPage, page * itemsPerPage);

  return (
    <Box>
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Type to search..."
        onChange={(e) => setSearchKeyword(e.target.value)}
        sx={{ marginBottom: 2 }}
      />
      <Grid container spacing={2}>
        {paginatedTemplates.map((template, index) => (
          <Grid item xs={4} key={index}>
            <Button
              variant="outlined"
              fullWidth
              onClick={() => onTemplateClick(template.templateId)}
            >
              {template.templateName}
            </Button>
          </Grid>
        ))}
      </Grid>
      {filteredTemplates.length > itemsPerPage && (
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
          <Pagination
            count={Math.ceil(filteredTemplates.length / itemsPerPage)}
            page={page}
            onChange={handleChangePage}
          />
        </Box>
      )}
    </Box>
  );
}