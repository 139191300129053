import { createContext, useContext, useState, useEffect } from 'react';
import { CognitoIdentityProviderClient, InitiateAuthCommand, SignUpCommand, ConfirmSignUpCommand, GetUserCommand, ForgotPasswordCommand, ConfirmForgotPasswordCommand, RespondToAuthChallengeCommand } from "@aws-sdk/client-cognito-identity-provider";

export const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

const client = new CognitoIdentityProviderClient({ region: "us-east-1" });
const cognitoAppClientId = "3dnh52kmpd0kiohn21k8e71b8a";

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [loading, setLoading] = useState(true);

useEffect(() => {
    const initAuth = async () => {
        const accessToken = localStorage.getItem('accessToken');
        const idToken = localStorage.getItem('idToken');

        if (accessToken && idToken) {
            try {
                const userData = await getUserData(accessToken);
                setUser(userData);
                setIsLoggedIn(true);
            } catch (error) {
                setUser(null);
                setIsLoggedIn(false);
            }
        } else {
            setUser(null);
            setIsLoggedIn(false);
        }

        setLoading(false);
    };

    initAuth();
}, []);

    const getUserData = async (accessToken) => {
        const command = new GetUserCommand({
            AccessToken: accessToken,
        });

        try {
            const response = await client.send(command);
            return response.UserAttributes.reduce((acc, attribute) => {
                acc[attribute.Name] = attribute.Value;
                return acc;
            }, {});
        } catch (error) {
            throw error;
        }
    };

    const signIn = async (username, password) => {
        const authCommand = new InitiateAuthCommand({
            AuthFlow: "USER_PASSWORD_AUTH",
            ClientId: cognitoAppClientId,
            AuthParameters: {
                USERNAME: username,
                PASSWORD: password,
            },
        });

        try {
            const response = await client.send(authCommand);
            if (response.ChallengeName && response.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
                return { challengeName: 'NEW_PASSWORD_REQUIRED', session: response.Session };
            }
            if (response.AuthenticationResult) {
                const { AccessToken, IdToken } = response.AuthenticationResult;
                
                localStorage.setItem('accessToken', AccessToken);
                localStorage.setItem('idToken', IdToken);
                // Store refreshToken if needed

                const userData = await getUserData(AccessToken);
                setUser(userData);
                setIsLoggedIn(true);
            }
        } catch (error) {
            return { error: error.message };
        }
    };

    const signUp = async (email, password) => {
        const signUpCommand = new SignUpCommand({
            ClientId: cognitoAppClientId,
            Username: email,
            Password: password,
            UserAttributes: [
                { Name: "email", Value: email },
                // include other attributes if needed
            ],
        });
        await client.send(signUpCommand);
    };

    const confirmSignUp = async (email, code) => {
        const confirmSignUpCommand = new ConfirmSignUpCommand({
            ClientId: cognitoAppClientId,
            Username: email,
            ConfirmationCode: code,
        });
        await client.send(confirmSignUpCommand);
    };

    const signOut = async () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('idToken');
        // Remove refreshToken if stored

        setUser(null);
        setIsLoggedIn(false);
    };

    const forgotPassword = async (email) => {
        const forgotPasswordCommand = new ForgotPasswordCommand({
            ClientId: cognitoAppClientId,
            Username: email,
        });
        await client.send(forgotPasswordCommand);
    };
    
    const confirmForgotPassword = async (email, code, newPassword) => {
        const confirmForgotPasswordCommand = new ConfirmForgotPasswordCommand({
            ClientId: cognitoAppClientId,
            Username: email,
            ConfirmationCode: code,
            Password: newPassword,
        });
        await client.send(confirmForgotPasswordCommand);
    };
    
    const respondToNewPasswordRequiredChallenge = async (email, session, newPassword, additionalAttributes = {}) => {
        const params = {
            ChallengeName: 'NEW_PASSWORD_REQUIRED',
            ClientId: cognitoAppClientId,
            Session: session,
            ChallengeResponses: {
                ...additionalAttributes,
                USERNAME: email,
                NEW_PASSWORD: newPassword,
            },
        };
    
        const command = new RespondToAuthChallengeCommand(params);
    
        try {
            const response = await client.send(command);
            if (response.AuthenticationResult) {
                const { AccessToken, IdToken } = response.AuthenticationResult;
                
                localStorage.setItem('accessToken', AccessToken);
                localStorage.setItem('idToken', IdToken);
    
                const userData = await getUserData(AccessToken);
                setUser(userData);
                setIsLoggedIn(true);
    
                return { success: true };
            }
        } catch (error) {
            return { error: error.message };
        }
    };

    const value = {
        user,
        isLoggedIn,
        loading,
        signIn,
        signOut,
        signUp,
        confirmSignUp,
        forgotPassword,
        confirmForgotPassword,
        respondToNewPasswordRequiredChallenge
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
};