import { useRef, useState, useEffect, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, TextField, CircularProgress } from '@mui/material';
import CodeMirror from '@uiw/react-codemirror';
import { html } from '@codemirror/lang-html';
import { CustomPageStudioCodeButtons } from './CustomPageStudioCodeButtons';
import { fetchDataFromApi } from '../reusable_scripts/fetch_data';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { AlertContext } from './AlertContext';
import { AlertSnackbar } from './AlertSnackbar';

export function CustomPageStudioDialog({ openDialog, handleCloseDialog, colorMode, onActionCompleted }) {
  const editorRef = useRef();
  const [code, setCode] = useState('');
  const [pageName, setPageName] = useState('');
  const [pageDescription, setPageDescription] = useState('');
  const [dataLoaded, setDataLoaded] = useState(false);
  const [pageCodeUrl, setPageCodeUrl] = useState('');
 
  const [searchParams] = useSearchParams();
  const pageIdParam = searchParams.get('pageId');
  const [isNew, setIsNew] = useState(true);

  const { handleAddMessage } = useContext(AlertContext);
 
useEffect(() => {
  setDataLoaded(false);
  if (pageIdParam) {
    setIsNew(false);
    // Fetch templates data
    fetchDataFromApi('/custom-page-studio/pages/' + pageIdParam)
      .then(data => {
        setCode(data.pageCode);
        setPageName(data.pageName);
        setPageDescription(data.pageDescription);
        setPageCodeUrl(data.pageCodeUrl);
        setDataLoaded(true);
      })
      .catch(error => {
        console.error('Error page:', error);
        setDataLoaded(true);
      });

  } else {
    // This else block will execute if there is no pageIdParam, which means creating a new page
    setIsNew(true);
    setCode('');
    setPageName('');
    setPageDescription('');
    setPageCodeUrl('');
    setDataLoaded(true);
  }
}, [pageIdParam]);
  
useEffect(() => {
  console.log('dataLoaded', dataLoaded);
}, [dataLoaded]);

  //RELATED TO THE REVIEW
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const handleCodePreview = () => {
    setIsPreviewOpen(!isPreviewOpen);
  };
const createBlobUrl = () => {
  const cssReset = `
    * { margin: 0; padding: 0; box-sizing: border-box; }
    body { background: white; color: black; } // Set base styles here
  `;
  const htmlContent = `
    <!DOCTYPE html>
    <html>
      <head>
        <style>${cssReset}</style>
        <!-- Include other styles here -->
      </head>
      <body>
        ${code}
      </body>
    </html>
  `;
  const blob = new Blob([htmlContent], { type: 'text/html' });
  return URL.createObjectURL(blob);
};
  
  


  const onCodeChange = (value) => setCode(value);
  
  const insertCodeAtCursor = (insertion) => {
    const currentCode = code;
    const cursorPosition = editorRef.current?.view?.state.selection.main.head ?? currentCode.length;
    const beforeCursor = currentCode.substring(0, cursorPosition);
    const afterCursor = currentCode.substring(cursorPosition);
    const newCode = beforeCursor + insertion + '\n' + afterCursor;
    setCode(newCode); // Update the code state with the new code
  };
  
  const handleNameChange = (event) => setPageName(event.target.value);
  const handleDescriptionChange = (event) => setPageDescription(event.target.value);


  const CopyToClipboardComponent = () => {
    const copyToClipboard = async (textToCopy) => {
      try {
        await navigator.clipboard.writeText(textToCopy);
        handleAddMessage('success', 'Embed Code Successfully Copied!');
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    };
  
    const textToCopy = `
      <style>
        .full-viewport {
          position: fixed;
          left: 0;
          width: 100vw;
          z-index: 10;
        }
      </style>
      <script>
        function adjustObjectPosition() {
          var headerHeight = document.querySelector('header').offsetHeight;
          var fullViewport = document.querySelector('.full-viewport');
          fullViewport.style.top = headerHeight + 'px';
          fullViewport.style.height = \`calc(100vh - \${headerHeight}px)\`;
        }
        // Adjust position on load and window resize
        window.onload = adjustObjectPosition;
        window.onresize = adjustObjectPosition;
      </script>
      <div class="full-viewport">
        <object type="text/html" data="${pageCodeUrl}" style="width: 100%; height: 100%;"></object>
      </div>
    `;
  
    return (
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button 
          variant="outlined" 
          onClick={() => copyToClipboard(textToCopy)}
          sx={{ display: 'flex', alignItems: 'center' }}
          startIcon={<ContentCopyIcon />}
        >
          Copy Embed Code
        </Button>
      </Box>
    );
  };

const handleSave = async (e) => {
  e.preventDefault();

  // Check if the 'Name' field is empty
  if (!pageName.trim()) {
    handleAddMessage('error', 'Please add a Name before saving.');
    return; // Prevent further execution of the function
  }

  // Check if the 'code' field is empty
  if (!code.trim()) {
    handleAddMessage('error', 'Please add some code before saving.');
    return; // Prevent further execution of the function
  }

  const requestData = {
      pageName: pageName,
      pageDescription: pageDescription,
      pageCode: code
  };
  
  const request_body = JSON.stringify(requestData); // Stringify the requestData
  console.log('Request Data:', request_body); // Check if the request data looks correct
  
  try {
    let response;

    if (isNew) {
      response = await fetchDataFromApi('/custom-page-studio/pages', 'POST', request_body);
      console.log('Create Mode Response:', response); // Check the response in create mode
      onActionCompleted('page-created');
    } else {
      response = await fetchDataFromApi('/custom-page-studio/pages/' + pageIdParam, 'POST', request_body);
      console.log('Edit Mode Response:', response);
      onActionCompleted('page-updated');
    }

    handleLocalCloseDialog();
    setCode('');
    setPageName('');
    setPageDescription('');
    setDataLoaded(false);

  } catch (error) {
    console.error('Error submitting form:', error);
    // Show error to the user, if appropriate
  }
};

  const handleLocalCloseDialog = () => {
    setDataLoaded(false); // Reset local state
    setCode('');         // Reset other states if needed
    setPageName('');
    setPageDescription('');
    handleCloseDialog(); // Call parent's handleCloseDialog
  };

  return (
    <>
    <AlertSnackbar />
    <Dialog 
      open={openDialog} 
      onClose={handleLocalCloseDialog} 
      aria-labelledby="form-dialog-title"
      PaperProps={{ style: { maxWidth: '100%', width: '90vw' }}}
      maxWidth="xl"
    >
      <DialogTitle id="form-dialog-title">Code Editor</DialogTitle>
      {!dataLoaded ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <CircularProgress size={75} />
        </Box>
      ) : (
      <>
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center', 
        '& > *:not(:last-child)': { // Apply margin to all child elements except the last one
          marginRight: 2 // Adjust this value as needed for your design
        }
      }}>
        <Button variant="contained" onClick={handleCodePreview}>Preview</Button>
        {!isNew && <CopyToClipboardComponent />}
      </Box>
      <DialogContent sx={{ display: 'flex' }}>
        <Box sx={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
          width: '100vw'
        }}>
        <Box sx={{ display: 'flex', width: '100%', marginBottom: 2 }}>
          <CustomPageStudioCodeButtons onInsert={insertCodeAtCursor} />
          <Box>
              <CodeMirror
                ref={editorRef}
                value={code}
                onChange={onCodeChange}
                height="60vh"
                width="80vw"
                theme={colorMode}
                extensions={[html()]}
              />
          </Box>
        </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <TextField
              label="Name"
              fullWidth
              required
              value={pageName}
              onChange={handleNameChange}
              sx={{ marginRight: 1 }}
            />
            <TextField
              label="Description"
              fullWidth
              value={pageDescription}
              onChange={handleDescriptionChange}
              multiline
              rows={2}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleSave}>Save</Button>
        <Button variant="outlined" onClick={handleLocalCloseDialog}>Cancel</Button>
      </DialogActions>
      </>
      )}
    </Dialog>
      <Dialog
        open={isPreviewOpen}
        onClose={() => setIsPreviewOpen(false)}
        aria-labelledby="preview-dialog-title"
        PaperProps={{ style: { maxWidth: '100%', width: '90vw', height: '100vh' }}}
        maxWidth="xl"
      >
        <DialogTitle id="preview-dialog-title">Preview</DialogTitle>
        <DialogContent>
          <iframe src={createBlobUrl()} style={{ width: '100%', height: '95%' }} title="Preview" />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setIsPreviewOpen(false)}>Close Preview</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
