import { useState, useEffect, useRef } from 'react';
import { Button, Typography, Card, Table, TableHead, TableRow, TableCell, TableBody, Switch, Box, TextField, CircularProgress, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { fetchDataFromApi } from './reusable_scripts/fetch_data';

export function AccountManagement() {
    const pageLoad = useRef(true);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [users, setUsers] = useState([]);
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [maxUsers, setMaxUsers] = useState(0);
    const [maxAdmins, setMaxAdmins] = useState(0);
    
    const [userCount, setUserCount] = useState(0);
    const [adminCount, setAdminCount] = useState(0);

    useEffect(() => {
        if(pageLoad.current){
          pageLoad.current = false;

          // Fetch templates data
          fetchDataFromApi('/admin-management')
            .then(data => {
              setUsers(data);
      
              setUserCount(data.filter(item => item.role === 'user').length);
              setAdminCount(data.filter(item => ['super_admin', 'admin'].includes(item.role)).length);
              
            })
            .catch(error => {
              console.error('Error:', error);
            });
            
          fetchDataFromApi('/client-limits?type=users')
            .then(data => {
              setMaxUsers(data.max);
            })
            .catch(error => {
              console.error('Error:', error);
            });
            
          fetchDataFromApi('/client-limits?type=admins')
            .then(data => {
              setMaxAdmins(data.max);
              setDataLoaded(true);
            })
            .catch(error => {
              console.error('Error:', error);
            });
        }
    }, []);
    
    function generateUserTable(data) {
        return (
          <Card>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Email Address</TableCell>
                        <TableCell align="center">Role</TableCell>
                        <TableCell align="center">Account Verified</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((item, index) => (
                        <TableRow key={index}>
                            <TableCell align="center">{item.emailAddress}</TableCell>
                            <TableCell align="center">{item.role}</TableCell>
                            <TableCell align="center">
                                <Switch checked={item.emailVerified}/>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
          </Card>
        );
    }
    
    const handleBillingRedirect = () => {
        window.open("https://billing.stripe.com/p/login/8wMaGZfsY7uT9eEeUU", "_blank");
    };
    
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        
        try {
            setIsLoading(true);
            const requestData = { email, role };
            const response = await fetchDataFromApi('/admin-management', 'POST', JSON.stringify(requestData));
            console.log('Create Mode Response:', response); // Check the response in create mode
            setEmail('');
            setIsLoading(false);
        } catch (error) {
          console.error('Error submitting form:', error);
          setIsLoading(false);
        }
    };

    return (
        <Box sx={{ textAlign: 'center', maxWidth: '80%', mx: 'auto', py: 4 }}>
          {!dataLoaded ? (
            <CircularProgress size={75} />
          ) : (
            <Box>
              <Card sx={{ padding: '2%', marginBottom: '4%', borderRadius: '16px' }}>
                <Typography variant="h5"  sx={{ paddingBottom: '2%' }} gutterBottom>Billing</Typography>
                <Button variant="outlined" onClick={handleBillingRedirect}>
                  Manage billing here
                </Button>
              </Card>
              <Card sx={{ padding: '2%', marginBottom: '4%', borderRadius: '16px' }}>
                <Typography variant="h5"  sx={{ paddingBottom: '2%' }} gutterBottom>Team Management</Typography>
                {
                !((role === 'user' && userCount >= maxUsers) ||
                  (role === 'admin' && adminCount >= maxAdmins) ||
                  (adminCount >= maxAdmins && userCount >= maxUsers)) && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginBottom: 2 }}>
                    <FormControl
                      required
                      disabled={
                        (role === 'user' && userCount >= maxUsers) ||
                        (role === 'admin' && adminCount >= maxAdmins) ||
                        (adminCount >= maxAdmins && userCount >= maxUsers)
                      }
                      sx={{ flexGrow: 1 }}
                      >
                      <InputLabel>Role</InputLabel>
                      <Select
                        label='Role'
                        value= {role || ''}
                        onChange={(e) => setRole(e.target.value)}
                      >
                          <MenuItem value='admin'>Admin</MenuItem>
                          <MenuItem value='user'>User</MenuItem>
                      </Select>
                    </FormControl>
                    <TextField
                      label="Email"
                      required
                      sx={{ flexGrow: 1 }}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      disabled={
                        (role === 'user' && userCount >= maxUsers) ||
                        (role === 'admin' && adminCount >= maxAdmins) ||
                        (adminCount >= maxAdmins && userCount >= maxUsers)
                      }
                    />
                    <Button
                      onClick={handleFormSubmit}
                      variant="contained"
                      disabled={
                        (role === 'user' && userCount >= maxUsers) ||
                        (role === 'admin' && adminCount >= maxAdmins) ||
                        (adminCount >= maxAdmins && userCount >= maxUsers)
                      }
                      >
                      Add
                    </Button>
                  </Box>
                )}
                {generateUserTable(users)}
              </Card>
            </Box>
          )}
        </Box>
    );
}